import React from "react";
import { useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import HomePage from './HomePage';
import TemplatesPage from './TemplatesPage';
import WallArtPage from './WallArtPage';
import ContactPage from './ContactPage';

// import '../css/shared.css';

const divVariants = {
    expanded: {
        width: "100%",
        transition: {
            duration: 1.2,
            ease: [0.83, 0, 0.17, 1]
        }
    },
    collapsed: {
        width: "0%",
        transition: {
            duration: 1.2,
            ease: [0.83, 0, 0.17, 1]
        }
    }
};

const tagVariants = {
    show: {
        opacity: 1,
        transition: {
            // delay: 1,
            duration: 1.2,
            ease: [0.83, 0, 0.17, 1]
        }
    },
    hidden: {
        opacity: 0,
        transition: {
            duration: 1.2,
            ease: [0.83, 0, 0.17, 1]
        }
    }
};

const Pages = (props) => {
    let location = useLocation();
    let data = props.data;

    return data.map((item, i) => {
        const components = {
            "home": HomePage,
            "templates": TemplatesPage,
            "wallart": WallArtPage,
            "contact": ContactPage,
        };

        const DefaultError = () => {
            return <div>Error, no page found</div>;
        };
        const Tag = components[item.id] || DefaultError;
        const isActive = location.pathname === item.url;

        return (
            <motion.div
                layout
                variants={divVariants}
                animate={isActive ? "expanded" : "collapsed"}
                initial={false}
                data-section={item.id}
                key={item.id}
                style={{
                    overflowX: "hidden",
                    zIndex: i,
                    display: "block",
                    position: "relative",
                }}
            >
                <AnimatePresence>
                    {isActive && (
                        <motion.div
                            layout
                            variants={tagVariants}
                            initial="hidden"
                            animate="show"
                            exit="hidden"
                            style={{
                                padding: 0,
                                maxWidth: "100%",
                                maxHeight: "100%",
                                width: "100%",
                                height: "100%",
                                overflowX: "hidden"
                            }}
                        >
                            <Tag data={data} />
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.div>
        );
    });
};

export default Pages;
