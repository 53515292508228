import { motion } from "framer-motion";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useState } from "react";

const useStyles = () => {
    const styles = {
        container: {
            minHeight: "100vh",
            width: "100%",
            overflow: "hidden",
        },
        main: {
            backgroundColor: "#f8e4e3",
            minHeight: "100vh",
            width: "100%",
            alignContent: "center",
            display: "flex",
            flexWrap: "wrap",
            paddingTop: "80px",
            overflow: "hidden",
        },
        formFiller: {
            width: "39%",
        },
        formContainer: {
            marginTop: "100px",
            width: "60%",
        },
        formHelper: {
            color: "pink",
            marginTop: "1px",
            marginLeft: "25px",
        },
        formControl: {
            marginTop: "15px",
            minWidth: "300px",
        },
        textField: {
            color: "#777",
        },
        hoverLeft: {
            transform: "translate(100px, 100px) scale(0.6)",
        },
        hoverBottomRight: {
            position: "absolute",
            bottom: 0,
            right: 0,
        },
        hoverTop: {
            position: "absolute",
            top: -250,
            right: 10,
            transform: "scale(0.8)",
        },
        emailMessage: {  //f44336
            '& a': {
                color: "#f44336"
            }
        }
    }
    return makeStyles(styles)();
}


const CustomTextField = withStyles({
    root: {
        '& label': {
            marginLeft: "10px",
        },
        '& label.Mui-focused': {
            marginLeft: "0px",
            color: 'pink',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'pink',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'pink',
                borderRadius: '25px',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'pink',
            },
        },
    },
})(TextField);


const WhiteButton = withStyles((theme) => ({
    root: {
        marginTop: "20px",
        padding: "15px",
        paddingLeft: "40px",
        paddingRight: "40px",
        borderRadius: "35px",
        backgroundColor: "white",
        '&:hover': {
            backgroundColor: "gray",
        },
    },
}))(Button);


function ContactPage(props) {
    const [sent, setSent] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const styles = useStyles();
    const sendContactForm = (name, email, message) => {
        const requestOptions = {
            method: 'POST',
            mode: 'no-cors',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name, email, message })
        };
        fetch('https://api.switch.rs/milenaduric/contact_form_UFLItuOAVt', requestOptions)
            .then(response => {
                console.log('should be sent');
                if (response.status === 0) {
                    setName("");
                    setEmail("");
                    setMessage("");
                    setSent(true);
                }
            })
    }
    let contactForm;
    if (!sent) {
        contactForm = <form style={{ borderTop: "solid 1px black" }}>
            <FormControl className={styles.formControl}>
                {/* Name */}
                <CustomTextField
                    id="unique-name"
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                    className={styles.textField}
                    inputProps={{ className: styles.textField }}
                    label="Your Name"
                    variant="outlined"
                    aria-describedby="text-helper" />
                <FormHelperText className={styles.formHelper} id="name-helper">How can I call you?</FormHelperText>
            </FormControl>
            <br />
            <FormControl className={styles.formControl}>
                {/* Email */}
                <CustomTextField
                    id="unique-email"
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                    className={styles.textField}
                    inputProps={{ className: styles.textField }}
                    label="Your Email Address"
                    variant="outlined"
                    aria-describedby="text-helper" />
                <FormHelperText className={styles.formHelper} id="email-helper">I'll never share your email.</FormHelperText>
            </FormControl>
            <br />
            <FormControl className={styles.formControl}>
                {/* Text */}
                <CustomTextField
                    id="unique-text"
                    onChange={(event) => setMessage(event.target.value)}
                    value={message}
                    className={styles.textField}
                    inputProps={{ className: styles.textField }}
                    label="Your Message"
                    variant="outlined"
                    aria-describedby="text-helper"
                    multiline />
                <FormHelperText className={styles.formHelper} id="text-helper">How can I help?</FormHelperText>
            </FormControl>
            <br />
            <WhiteButton style={{
                marginBottom: "30px",
            }}>
                <Typography
                    onClick={() => sendContactForm(name, email, message)}
                    style={{
                        fontFamily: "PlayFair",
                        color: "black",
                        fontWeight: "bold",
                    }}>SEND MESSAGE</Typography>
            </WhiteButton>
        </form>
    } else {
        contactForm = <>
            <h3>Message Received</h3>
            <p>Thank you for your enquiry...</p>
        </>
    }
    return (
        <motion.div className={styles.container}
            style={{}}
            variants={props.animationVariants}
            initial="hidden"
            animate="show"
            exit="hidden">
            <motion.div className={styles.hoverTop}>
                <img src="/contact-page/contact-page-hover-top.png" alt="hover-top"></img>
            </motion.div>
            <motion.div className={styles.hoverBottomRight}>
                <img src="/contact-page/contact-page-hover-right.png" alt="hover-right"></img>
            </motion.div>
            <div className={styles.main}>
                <div className={styles.formFiller}>
                    <div className={styles.hoverLeft}>
                        <img align="right" src="/contact-page/contact-page-hover-left.png" alt="hover-left"></img>
                    </div>
                </div>
                <div className={styles.formContainer}>
                    <div>
                        <img src="/contact-page/contact-page-hover-text.png" alt="hover-text"></img>
                    </div>
                    {contactForm}
                    <p className={styles.emailMessage}>...or contact me through my <a href="mailto:always.ak.design@gmail.com">email</a></p>
                </div>
            </div>
        </motion.div >
    );
}

export default ContactPage;
