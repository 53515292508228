/*
{
    key: 1,
    title: "TITLE",
    id: "fitness-planner-001",
    url: "",
    description: "",
    previewImagesCount: 2,
}
*/

export const templatesData = [{
    key: 887176109,
    title: "44 Realtor Instagram Stories",
    id: "realtor-instagram-002",
    url: {
        text: "Shop on Etsy",
        href: "https://www.etsy.com/listing/887176109",
    },
    description: "This is a unique templates bundle perfect for realtors, real estate agents and anyone who finds themselves in need of creating real estate related Instagram stories.",
    previewImagesCount: 7,
}, {
    key: 881273253,
    title: "36 Realtor Canva Instagram Templates",
    id: "realtor-instagram-001",
    url: {
        text: "Shop on Etsy",
        href: "https://www.etsy.com/listing/881273253",
    },
    description: "This is a unique templates bundle perfect for realtors, real estate agents and anyone who finds themselves in need of creating real estate related Instagram posts.",
    previewImagesCount: 8,
}, {
    key: 860310822,
    title: "20 Canva Instagram Feed Templates",
    id: "instagram-feed-002",
    url: {
        text: "Shop on Etsy",
        href: "https://www.etsy.com/listing/860310822",
    },
    description: "This is perfect for bloggers, content creators, digital marketers, and anyone who finds themselves in need of creating Instagram posts.",
    previewImagesCount: 6,
}, {
    key: 873585293,
    title: "Canva Instagram Feed Templates",
    id: "instagram-feed-001",
    url: {
        text: "Shop on Etsy",
        href: "https://www.etsy.com/listing/873585293",
    },
    description: "This is perfect for bloggers, content creators, digital marketers, and anyone who finds themselves in need of creating Instagram posts.",
    previewImagesCount: 6,
}, {
    key: 983608242,
    title: "20+ Pages Pink Journal Workbook Template",
    id: "journal-workbook-004",
    url: {
        text: "Shop on Etsy",
        href: "https://www.etsy.com/listing/983608242",
    },
    description: "Lead your clients through unprecedented transformation, personal growth and consciousness expansion with vision, goal-settings and action-taking prompts. Get your clients writing down their vision and goals, planning their year and their months, and getting down to specific action steps to take towards their transformation.",
    previewImagesCount: 10,
}, {
    key: 935213988,
    title: "20+ Pages Journal Workbook Template",
    id: "journal-workbook-003",
    url: {
        text: "Shop on Etsy",
        href: "https://www.etsy.com/listing/935213988",
    },
    description: "Lead your clients through unprecedented transformation, personal growth and consciousness expansion with vision, goal-settings and action-taking prompts. Get your clients writing down their vision and goals, planning their year and their months, and getting down to specific action steps to take towards their transformation.",
    previewImagesCount: 10,
}, {
    key: 879125413,
    title: "Yoga Instagram Post Template",
    id: "yoga-instagram-001",
    url: {
        text: "Shop on Etsy",
        href: "https://www.etsy.com/listing/879125413",
    },
    description: "This is perfect for yoga, mindfulness and workout bloggers, content creators, digital marketers and anyone who finds themselves in need of creating Yoga-related Instagram posts.",
    previewImagesCount: 7,
}, {
    key: 1039517257,
    title: "Fitness Ebook",
    id: "fitness-planner-001",
    url: {
        text: "Shop on Etsy",
        href: "https://www.etsy.com/listing/1039517257",
    },
    description: "Create your own Fitness eBook with this editable Fitness and Health Canva template. ALL CONTENT IS DONE FOR YOU! NO PLACEHOLDER TEXT - SAVE TIME AND MONEY!",
    previewImagesCount: 9,
}, {
    key: 1034966513,
    title: "Yoga Ebook Template",
    id: "yoga-planner-001",
    url: {
        text: "Shop on Etsy",
        href: "https://www.etsy.com/listing/1034966513",
    },
    description: "Create your own Yoga eBook with this Canva template. ALL CONTENT IS DONE FOR YOU! NO PLACEHOLDER TEXT - SAVE TIME AND MONEY!",
    previewImagesCount: 10,
}, {
    key: 990359531,
    title: "Fitness Planner Canva Template",
    id: "fitness-planner-002",
    url: {
        text: "Shop on Etsy",
        href: "https://www.etsy.com/listing/990359531",
    },
    description: "20 Pages Fitness Planner Canva Template for Fitness Coaches, Health Bloggers and Fitness Influencers who would like to grow their business by creating digital resources.",
    previewImagesCount: 10,
}, {
    key: 935215742,
    title: "20+ Pages Peach Journal Workbook Template",
    id: "journal-workbook-001",
    url: {
        text: "Shop on Etsy",
        href: "https://www.etsy.com/listing/935215742",
    },
    description: "Lead your clients through unprecedented transformation, personal growth and consciousness expansion with vision, goal-settings and action-taking prompts. Get your clients writing down their vision and goals, planning their year and their months, and getting down to specific action steps to take towards their transformation.",
    previewImagesCount: 10,
}, {
    key: 997593849,
    title: "20+ Pages Beige Journal Workbook Template",
    id: "journal-workbook-002",
    url: {
        text: "Shop on Etsy",
        href: "https://www.etsy.com/listing/997593849",
    },
    description: "Lead your clients through unprecedented transformation, personal growth and consciousness expansion with vision, goal-settings and action-taking prompts. Get your clients writing down their vision and goals, planning their year and their months, and getting down to specific action steps to take towards their transformation.",
    previewImagesCount: 10,
}];