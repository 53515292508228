import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';

const useStyles = makeStyles({
    root: {
        maxWidth: 331,
    },
    media: {
        height: 265,
        width: 331,
    },
});

const startHoverEffect = (event) => {
    // console.log(event.relatedTarget.children)
    // swap classes that control elevation
}

const endHoverEffect = (event) => {
    // console.log(event.relatedTarget.children)
    // swap classes that control elevation
}

const generateThumbnailPath = (itemId) => {
    return `/image/thumbnails/${itemId}.png`;
}

function ShopItem(props) {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const item = props.item;
    const thumbnail = generateThumbnailPath(item.id);
    const handleMouseClick = (event) => {
        history.push(`${location.pathname}/${event.currentTarget.dataset.key}`)
    };

    return (
        <motion.div layoutId={`card-layout-${item.key}`}>
            <Card data-key={item.key} className={classes.root} onClick={handleMouseClick} onMouseEnter={startHoverEffect} onMouseLeave={endHoverEffect}>
                <motion.div layoutId={`card-action-${item.key}`}>
                    <CardActionArea>
                        <motion.div layoutId={`card-image-${item.key}`}>
                            <CardMedia
                                className={classes.media}
                                image={thumbnail}
                            />
                        </motion.div>
                        <motion.div layoutId={`card-content-${item.key}`}>
                            <CardContent>
                                <motion.div layoutId={`card-title-${item.key}`}>
                                    <Typography>
                                        {item.title}
                                    </Typography>
                                </motion.div>
                                {/* <Typography variant="body2" color="textSecondary" component="p">    
                                    Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                                    across all continents except Antarctica
                                </Typography> */}
                            </CardContent>
                        </motion.div>
                    </CardActionArea>
                </motion.div>
                {/* <CardActions>
                    <Button size="small" color="primary">
                    Share
                    </Button>
                    <Button size="small" color="primary">
                    Learn More
                    </Button>
                </CardActions> */}
            </Card>
        </motion.div >
    );
}

export default ShopItem;