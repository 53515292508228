import React from "react";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import ShopItem from "./ShopItem";


const prepareRows = (data, rowSize) => {
    const preparedRows = [];
    let row = [];
    for (let i = 0; i < data.length; i++) {
        if (row.length < rowSize) row.push(data[i]);
        else {
            preparedRows.push(row);
            row = [];
            row.push(data[i]);
        }
    }
    if (row.length > 0) preparedRows.push(row);
    return preparedRows;
}


function ShopItems(props) {
    const data = prepareRows(props.data, props.rowSize);
    return (
        <Container>
            <img src={props.shopBackground} style={{ width: "100%", marginBottom: "50px" }} alt="" />
            {data.map((dataRow, index) => (
                <Grid key={index} item xs={12}>
                    <Grid container justifyContent="center" spacing={10}>
                        {dataRow.map((itemData) => (
                            <Grid key={itemData.key} item>
                                <ShopItem item={itemData} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            ))}
        </Container>
    );
}

export default ShopItems;