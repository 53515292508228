import { motion } from "framer-motion";

import "../css/WallArtPage.css";

function WallArtPage(props) {

    return (
        <motion.div className="wallart_page"
            variants={props.animationVariants}
            initial="hidden"
            animate="show"
            exit="hidden">
        </motion.div>
    );
}

export default WallArtPage;
