
import { Route, BrowserRouter as Router } from "react-router-dom";

import PagesLayout from './pages/PagesLayout';
import Shop from './pages/shops/Shop';
import { templatesData } from './pages/shops/data/templates';
import { wallartData } from './pages/shops/data/wallart';

import './App.css';

export const data = [
  {
    title: "home",
    id: "home",
    url: "/"
  },
  {
    title: "templates",
    id: "templates",
    url: "/templates"
  },
  {
    title: "wall art",
    id: "wallart",
    url: "/wallart"
  },
  {
    title: "contact",
    id: "contact",
    url: "/contact"
  },
];

function App() {

  return (
    <Router>
      <Route
        path={["/shop-templates/:id", "/shop-templates"]}
        render={(props) => (
          <Shop
            {...props}
            data={templatesData}
            shopBackground="/templates-shop-back-top.png" />
        )} />
      <Route
        path={["/shop-wallart/:id", "/shop-wallart"]}
        render={(props) => (
          <Shop
            {...props}
            data={wallartData}
            shopBackground="/wallart-shop-back-top.png" />
        )} />
      <PagesLayout data={data} />
    </Router >
  );
}

export default App;
