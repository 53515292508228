import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import { motion } from 'framer-motion';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Carousel from 'react-material-ui-carousel';


const useStyles = (windowWidth, windowHeight) => {
    let imgWidth = windowHeight / 1.117128463476071 * 0.823529411764706;
    let imgHeight = windowHeight / 1.396850393700787 * 0.823529411764706;
    if (windowWidth < imgWidth + 40) {
        const new_imgWidth = windowWidth - 40;
        imgHeight = imgHeight * (new_imgWidth / imgWidth);
        imgWidth = new_imgWidth;
    }

    let width = `${imgWidth}px`;
    let height = `${imgHeight}px`;

    const styles = {
        overlay: {
            position: "fixed",
            background: "rgba(0, 0, 0, 0.8)",
            willChange: "opacity",
            top: 0,
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            width: "100%",
        },
        grid: {
            marginTop: "5vh",
        },
        card: {
            maxWidth: width,
        },
        media: {
            width: width,
            height: height,
        }
    }
    return makeStyles(styles)();

}



const generateImagePaths = (itemId, imageCount) => {
    const images = [];
    for (let i = 0; i < imageCount; i++) {
        const padCounter = i.toString().padStart(3, '0')
        images.push(`/image/previews/${itemId}/${itemId}-p${padCounter}.jpg`)
    }
    return images;
}

function ReviewItem(props) {
    const location = useLocation();
    const item = props.item;
    const history = useHistory();
    const stopEventPropagation = (event) => {
        event.stopPropagation();
    };
    const handleOverlayClick = (event) => {
        event.stopPropagation();
        history.push(location.pathname.replace(`/${item.key}`, ''));
    }
    const classes = useStyles(props.width, props.height);
    console.log(classes);
    const images = generateImagePaths(item.id, item.previewImagesCount)

    return (
        <motion.div className={classes.overlay} animate onClick={handleOverlayClick}>
            <Grid className={classes.grid} container>
                <Grid item xs />
                <Grid item>
                    <motion.div layoutId={`card-layout-${item.key}`}>
                        <Card className={classes.card} onClick={stopEventPropagation}>
                            <motion.div layoutId={`card-action-${item.key}`}>
                                <motion.div layoutId={`card-image-${item.key}`}>
                                    <Carousel
                                        navButtonsProps={{
                                            style: {
                                                opacity: "10%",
                                            }
                                        }}>
                                        {images.map((img, i) => {
                                            return (
                                                <img key={i} className={classes.media} src={img} alt={`img-${i}`} />
                                            )
                                        })}
                                    </Carousel>
                                </motion.div>
                                <motion.div layoutId={`card-content-${item.key}`}>
                                    <CardContent>
                                        <motion.div layoutId={`card-title-${item.key}`}>
                                            <Typography>
                                                {item.title}
                                            </Typography>
                                        </motion.div>
                                        <motion.div animate>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                {item.description}
                                            </Typography>
                                        </motion.div>
                                    </CardContent>
                                </motion.div>
                            </motion.div>
                            <motion.div>
                                <CardActions>
                                    <Grid container>
                                        <Grid item xs></Grid>
                                        <Button size="small" color="primary" target="_blank" href={item.url.href}>
                                            {item.url.text}
                                        </Button>
                                        <Grid item xs></Grid>
                                    </Grid>
                                </CardActions>
                            </motion.div>
                        </Card>
                    </motion.div>
                </Grid>
                <Grid item xs />
            </Grid>
        </motion.div >
    );
}

export default ReviewItem;