/*
{
    key: 1,
    title: "TITLE",
    id: "fitness-planner-001",
    url: "",
    description: "",
    previewImagesCount: 2,
}
*/

export const wallartData = [
    {
        key: 1,
        title: "Wall Art One",
        id: "wall-art-001",
        url: {
            text: "Order Now",
            href: "",
        },
        description: "Enjoy this wallart",
        previewImagesCount: 6,
    },
    {
        key: 2,
        title: "Wall Art Two",
        id: "wall-art-002",
        url: {
            text: "Order Now",
            href: "",
        },
        description: "Enjoy this wallart",
        previewImagesCount: 6,
    },
    {
        key: 3,
        title: "Wall Art Three",
        id: "wall-art-003",
        url: {
            text: "Order Now",
            href: "",
        },
        description: "Enjoy this wallart",
        previewImagesCount: 5,
    },
];