import React from 'react';
import CreateReactClass from 'create-react-class';
import { NavLink, useLocation } from "react-router-dom";

import '../css/NavDesktop.css';

const NavButton = CreateReactClass({
	onMouseOver: function () {
		this.props.onHover(this.props.index);
	},
	render: function () {
		const isActive = this.props.location.pathname === this.props.url;
		return <div
			className={"nav_button " + this.props.additionalClasses}
			onMouseOver={this.onMouseOver}>
			<NavLink
				exact={true}
				activeClassName="active"
				to={this.props.url}>
				<div style={{ fontWeight: isActive ? "bold" : "lighter", fontFamily: "PlayFair" }}>
					{this.props.val}
				</div>
			</NavLink>
		</div>
	}
})

const NavButtons = CreateReactClass({
	getInitialState: function () {
		return { selected: "", hover: "" };
	},
	getHoverState: function (index) {
		if (this.state.hover - 1 === index || this.state.hover + 1 === index) {
			return "sibling";
		} else if (this.state.hover === index) {
			return "current";
		}
		return "";
	},
	onHover: function (index) {
		this.setState({ hover: index });
	},
	onMouseOut: function () {
		this.setState({ hover: "" });
	},
	render: function () {
		var all_nav_buttons = this.props.navButtons.map(function (navButton, i) {
			let additionalClasses = this.getHoverState(i)
			if (this.props.navButtons.length - 1 === i) {
				additionalClasses = `${additionalClasses} last`;
			}
			return <NavButton
				index={i}
				key={navButton.id}
				val={navButton.title}
				additionalClasses={additionalClasses}
				location={this.props.location}
				onHover={this.onHover}
				url={navButton.url}
			/>
		}.bind(this));
		return <div onMouseOut={this.onMouseOut}> {all_nav_buttons}</div>;
	}
});

const NavDesktop = (props) => {

	const navButtons = props.data.slice(0, 4);
	const location = useLocation();

	return (
		<div className="nav_buttons">
			<NavButtons navButtons={navButtons} location={location} />
		</div>
	)
}

export default NavDesktop;
