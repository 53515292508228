import { motion } from "framer-motion";

import "../css/HomePage.css";

function HomePage(props) {
    return (
        <motion.div
            className="home_page"
            variants={props.animationVariants}
            initial="hidden"
            animate="show"
            exit="hidden">
        </motion.div>
    );
}

export default HomePage;
