import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';


const useStyles = () => {
    const styles = {
        templates_page: {
            backgroundImage: "url('/templates-page/templates-page-background.png')",
            backgroundSize: "cover",
            minHeight: "100vh",
            minWidth: "100vw",
        }
    }
    return makeStyles(styles)();
}

const CremeButton = withStyles((theme) => ({
    root: {
        padding: "15px",
        paddingLeft: "40px",
        paddingRight: "40px",
        borderRadius: "35px",
        backgroundColor: "#ebded4",
        '&:hover': {
            backgroundColor: "#dacec5",
        },
    },
}))(Button);

function TemplatesPage(props) {
    const styles = useStyles();
    return (
        <motion.div
            className={styles.templates_page}
            variants={props.animationVariants}
            initial="hidden"
            animate="show"
            exit="hidden">
            <motion.div style={{
                position: "absolute",
                top: "50%",
                right: "50%",
                transform: "translate(50%, -50%) scale(0.8)",
            }}>
                <img src="/templates-page/templates-page-hover-text.png" alt="hover-text" />
                <div style={{ display: "flex", }}>
                    <div style={{ flex: "auto" }}></div>
                    <div style={{ position: "absolute", right: "110px", bottom: "40px" }}>
                        <NavLink
                            style={{ textDecoration: "none", align: "right" }}
                            exact={true}
                            activeClassName="active"
                            to="/shop-templates">

                            <CremeButton>
                                <Typography style={{
                                    fontFamily: "PlayFair",
                                    color: "black",
                                    fontWeight: "bold",
                                    opacity: "0.6",
                                    float: "right"
                                }}>FIND A PERFECT TEMPLATE</Typography>
                            </CremeButton>
                        </NavLink>
                    </div>
                </div>
            </motion.div>
        </motion.div >
    );
}

export default TemplatesPage;
