import { useEffect, useState } from "react";
import { motion, AnimateSharedLayout } from "framer-motion";

import ReviewItem from './ReviewItem';
import ShopItems from "./ShopItems";
import "../../css/TemplatesShop.css";

const shopData = (data = [{ key: 0, title: "data not loaded" }], id = null) => {
    if (id !== null) {
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            if (item.key.toString() === id) return item;
        }
        return null;
    }
    return data;
}

function Shop(props) {
    let { id } = props.match.params;
    const [rowSize, setRowSize] = useState(3);
    const [width, setWidth] = useState();
    const [height, setHeight] = useState();
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
        if (width < 1300) {
            setRowSize(2);
        } else {
            setRowSize(3);
        }
    }
    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    });

    return (
        <AnimateSharedLayout crossfade>
            <motion.div>
                <ShopItems rowSize={rowSize} data={shopData(props.data)} shopBackground={props.shopBackground} />
                {id && <ReviewItem item={shopData(props.data, id)} width={width} height={height} />}
            </motion.div >
        </AnimateSharedLayout>
    );
}

export default Shop;