import { useLocation } from "react-router";
import { AnimateSharedLayout } from "framer-motion";

import NavDesktop from '../nav/NavDesktop';
import Pages from "./Pages";

const PagesLayout = (props) => {
    const location = useLocation();
    if (location.pathname.startsWith('/shop')) {
        return null;
    }
    return (
        <AnimateSharedLayout>
            <NavDesktop data={props.data} />
            <div>
                <nav
                    style={{
                        height: "100vh",
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                    }}
                >
                    <Pages data={props.data} />
                </nav>
            </div>
        </AnimateSharedLayout>
    );
};

export default PagesLayout;
